@import "styles/utils";

.subprofilesContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: #070523;
  z-index: 1990;

  @include phone {
    overflow-y: scroll;
    justify-content: unset;
  }

  .logoContainer {
    position: absolute;
    top: 60px;
    left: 70px;

    @include phone {
      position: relative;
      margin: 50px 0 20px 0;
      top: unset;
      left: unset;
    }

    & > div {
      img {
        @include phone {
          height: 73px;
        }
      }
    }
  }

  .closeBtnWrapper {
    position: absolute;
    top: 48px;
    right: 49px;

    @include phone {
      top: 10px;
      right: 0px;
    }

    button {
      padding: 20px;
      border: none;

      svg {
        height: 24px;
        width: 24px;

        path {
          fill: var(--primary-text-color);
        }
      }
    }
  }
}
