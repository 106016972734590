@import "styles/utils";

.container {
  @include phone {
    text-align: center;
    margin-top: 80px;
    display: block;
  }
}

.login {
  display: block;
  margin-top: 40px;
  font-size: 14px;
}
