@import "styles/utils";
@import "styles/variables";

.Container {
  font-size: 16px;
  font-weight: lighter;

  @include tabletDown {
    font-size: 14px;
  }
}

.Item {
  &:not(:first-child) {
    margin-top: 25px;
  }

  .ItemTitle {
    font-weight: normal;
    margin-right: 10px;
  }
}

.Table {
  margin-top: 25px;
  width: calc(100vw - 500px);
  min-width: 230px;
  max-width: 750px;
  min-height: 600px;
  @include phone {
    width: 90vw;
    min-height: 400px;
    overflow-y: scroll;
  }
  table {
    font-size: clamp(16px, 22px, 1.5vw);
    width: 100%;

    @include tabletDown {
      font-size: 14px;
    }

    thead {
      text-align: left;

      th {
        &:nth-child(1) {
          min-width: 100px;
        }
        &:nth-child(2) {
          min-width: clamp(100px, 240px, 10.5vw);
        }
        &:nth-child(3) {
          min-width: clamp(140px, 240px, 10.5vw);
        }
      }
    }

    thead tr th {
      font-weight: normal;
    }

    tbody {
      td {
        padding: 0.5em 1em 0.5em 0;
        font-weight: 300;
        white-space: nowrap;
        vertical-align: top;

        &:nth-child(2) {
          p {
            margin: 0;
            white-space: break-spaces;
          }

          span {
            white-space: pre;
          }
        }

        &:first-child {
          &:hover {
            cursor: pointer;
          }

          div {
            text-decoration: underline;
            text-decoration-thickness: 1px;
          }
        }
      }
    }
  }
}

.Description {
  font-size: 16px;
  margin-top: 70px;

  @include tabletDown {
    font-size: 10.08px;
  }
}
