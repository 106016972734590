@import "styles/utils";

.slider {
  display: flex;
  width: 100%;
  height: max-content;

  .sliderElement {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    label {
      margin: 0 26px;
      color: transparent;
      min-width: 50px;

      &::before {
        position: absolute;
        content: attr(title);
        margin-top: 3px;
        right: -22%;
        font-weight: lighter;
        color: var(--primary-text-color);
      }
    }

    &First {
      width: 12%;
      label {
        &::before {
          min-width: max-content;
          right: -50%;
        }
      }
    }
  }

  .sliderCheckBox {
    position: relative;
    height: 23px;
    width: 100%;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background-color: white;
      z-index: 10;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0%;
      top: 41%;
      background-color: white;
      z-index: 9;
    }
  }

  input[type="checkbox"] {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
  }

  input[type="checkbox"]:checked::before,
  input[type="checkbox"]:checked::after {
    background-color: var(--primary-color);
  }
}

.ageRestrictionText {
  margin: 30px 0 -20px 0;
  font-weight: lighter;
}
