@import "styles/utils";

.colorPicker {
  width: 100%;
  font-size: 16px;
  font-weight: lighter;

  label {
    margin-bottom: 20px;
  }

  .colorContainer {
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
    width: 100%;

    .active {
      border-width: 1px;
    }

    span {
      position: relative;
      margin: 0;
      width: 50px;
      height: 50px;
      border: 0px solid white;
      border-radius: 50%;
      overflow: hidden;

      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
