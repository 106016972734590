@import "styles/components";
@import "styles/utils";

.list {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 90%;
  @include phone() {
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .elementContainer {
      display: flex;
      flex:1;
      @include phone() {
        flex-basis: 50%;
        margin-bottom: 4rem;
      }
      @include phone-landscape() {
        flex-basis: 50%;
        margin-bottom: 4rem;
      }
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 110px;
        width: 200px;
        @include phone() {
          height: 70px;
          width: 150px;
        }
        > div > svg {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        @include typography(1.56rem, 1.6rem);
        font-weight: bold;
        @include marginVertical(1.3rem);
        @include phone() {
          margin-top: 4rem;
          @include typography(2rem, 2rem);
        }
      }
      .deviceName {
        @include typography(1.37rem, 1.5rem);
        @include marginVertical(0.8rem);
        font-weight: lighter;

        @include phone() {
          @include typography(1.6rem, 1.7rem);
        }
      }
    }
  }
}
