@import "src/styles/variables";
@import "src/styles/utils";

.detailsButton {
  @include prevent-tab-highlights;
  padding: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 1.28rem;
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--primary-text-color);
  border: none;
  cursor: pointer;

  svg {
    width: 45px;
    height: 45px;
    margin-right: 8px;

    @include tablet {
      width: $button-height-md;
      height: $button-height-md;
    }
  }
}
