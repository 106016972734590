@import "src/styles/utils";

.playBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none !important;
  box-shadow: none;
  padding: 20px 30px 50px 30px;
  z-index: 10;
  cursor: pointer;

  svg {
    height: 108px;
    width: 108px;

    path:first-child {
      fill: var(--primary-color);
    }

    &:hover {
      path:first-child {
        fill: var(--button-primary-hover-color);
      }
    }

    &:active {
      path:first-child {
        fill: var(--button-primary-active-color);
      }
    }

    @include desktopDown() {
      height: 90px;
      width: 90px;
    }
  }
}
