@import "styles/utils";

.Dialog {
  padding: 30px 40px 0px;
  min-width: 45vw;
  font-size: 16px;

  @include tablet {
    min-width: 70vw;
    font-size: 14px;
  }

  @include phone {
    padding-top: 130px;
  }

  .Container {
    max-width: 480px;
    margin: auto;
  }

  .Title {
    font-size: 25px;
    font-weight: bold;

    @include tablet {
      font-size: 20px;
    }

    @include phone {
      font-size: 16px;
    }
  }

  .Content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-weight: lighter;

    p {
      line-height: 20px;
    }
  }
}