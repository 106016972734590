@import "styles/utils";

.drawers {
  font-weight: lighter;
  :global {
    .Select-selector {
      min-width: 12rem;
      margin-right: 1rem;
      @include tablet {
        min-width: 10rem;
        margin-right: 0;
      }
      @include phone {
        min-width: 2rem;
        margin-right: 0;
      }
    }
    .Select-selection-item,
    .Select-selection-placeholder {
      @include phone {
        padding-right: 30px;
      }
    }
    .Select-arrow {
      margin-right: 5px;
    }
  }
  &:first-child {
    margin-left: -24px;
  }
  .labelBox {
    @include phone {
      display: flex;
      flex-direction: column;
    }
    .labelSortName {
      text-transform: uppercase;
      font-weight: bold;
    }

    .labelOptionName, .labelCategoryOptionName {
      @include phone {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .labelCategoryOptionName {
      margin: 0;
      display: inline;
      font-size: 1.375rem;
    }
  }
  .labelBoxPlaylist {
    display: inline-block;
    @include phone {
      width: 125px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &.emptyOptionNameInPlaylist {
      width: 150px;
      @include tablet {
       width: 125px;
      }
      @include phone {
        width: 80px;
       }
    }
  }
  .optionName {
    text-transform: capitalize;
  }
}
