@import "styles/utils";
@import "styles/variables";

.Button {
  justify-content: center;
  width: 100%;
  max-width: 215px;
  height: 48px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-right: 0;
  border-radius: $button-radius;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.82px;
  text-align: center;

  @include tablet {
    font-size: 16px;
    height: 38px;
  }
}

.Title {
  font-weight: bold;
  margin-top: 110px;

  @include tabletDown {
    margin-top: 88px;
  }
}

.Text {
  font-size: 14px;
  color: var(--primary-text-color);

  @include tablet {
    font-size: 11px;
  }

  a {
    font-weight: bold;
  }
}
