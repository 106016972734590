@import "styles/utils";
@import "styles/variables";

.Profile {
  @include prevent-tab-highlights;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
  margin: 72px 10px 10px 10px;

  @include phone {
    margin: 50px 10px 10px 10px;
  }

  .ProfileCircle {
    width: 98px;
    height: 98px;
    font-size: 62px;
    border-radius: 50%;
    position: relative;

    @include phone {
      width: 78px;
      height: 78px;
      font-size: 49px;
    }

    .ProfileLetter {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-transform: capitalize;
      font-weight: normal;
    }

    .ProfileEditIcon {
      border-radius: 50%;
      position: absolute;
      bottom: -10px;
      right: -10px;
      width: 35px;
      height: 35px;
      cursor: pointer;

      @include phone {
        bottom: -7px;
        right: -7px;
        width: 28px;
        height: 28px;
      }

      svg {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }

  .ProfileTitle {
    margin-top: 28px;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;

    @include phone {
      white-space: normal;
      font-size: 14.4px;
      margin-top: 22px;
    }
  }

  .ProfileTitleOverflow {
    text-overflow: ellipsis;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
  }

  .ProfileAdd {
    cursor: pointer;
    width: 98px;
    height: 98px;

    @include phone {
      width: 78px;
      height: 78px;
      font-size: 49px;
    }

    svg {
      height: auto;
      width: 100%;
    }
  }
}
