.list {
  display: block;

  &:first-of-type {
    padding-top: 0 !important;
  }

  :global {
    .slick-list {
      overflow: hidden !important;
      margin: 0 !important;
    }

    .slider-control-bottomcenter {
      width: 100%;
      left: 0;
      transform: none;
    }
  }
}
