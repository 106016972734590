@import "styles/utils";

.container {
  border-right: 1px solid #979797;
  padding-top: 5rem;
  min-width: 205px;

  @include desktop {
    padding-right: 5rem;
  }

  @include tablet {
    margin-top: 7rem;
    padding-top: 0;
  }

  @include phone {
    border: none;
    text-align: center;
    padding-top: 0;
  }
}

.menuContainer {
  @include phone {
    display: none;
  }
}

.menu {
  position: relative;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-bottom:70px;

  @include phone {
    display: block;
    margin: 0 50px 50px 50px;
  }
}

.titleContainer {
  display: none;
  margin-bottom: 6rem;

  @include phone {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.title {
  font-size: 1.7em;
  font-weight: bold;
  @include phone() {
    font-size: 24px;
  }
}

.sectionTitleContainer {
  display: none;
  align-items: center;

  @include phone {
    position:relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    > a {
      position: absolute;
      left:0;
      top:0;
    }
    > div {
      flex:1;
      align-items: center;
      justify-content: center;
    }
  }
}

.sectionTitle {
  font-size: 24px;
  line-height: 26px;
}

.back {
  font-size: 3.25rem;
  margin-right: 2rem;
  cursor: pointer;

  svg {
    display: block;
  }
}
