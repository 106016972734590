@import "styles/utils";

.forgotPin {
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin-bottom: 10px;
    font-size: 36px;
  }

  .description {
    margin-bottom: 26px;
    max-width: 479px;
    align-self: flex-start;
    font-size: 15px;
    font-weight: lighter;
  }

  form {
    min-width: 479px;

    @include tabletDown() {
      width: 100%;
      min-width: unset;
    }
  }

  .labelFieldContainer {
    position: relative;
    height: 78px;

    a {
      font-size: 15px;
    }

    & > span {
      height: 48px;
      width: 100%;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;

    button {
      background-color: #0031ff !important;
      max-width: 215px;
      height: 50px;
      &:disabled {
        background-color: #eeeeee !important;
      }
    }
  }

  .error {
    position: absolute;
    left: 50%;
    bottom: -100px;
    transform: translateX(-50%);
  }
}
