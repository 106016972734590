@import "styles/utils";
@import "styles/variables";

.modal {
  @include prevent-tab-highlights;
  cursor: pointer;
  z-index: 1009;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease;
  opacity: 0;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 12px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2.5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg-color);
  @include tabletDown {
    display: none;
  }

  .Image {
    z-index: -1;
    object-fit: cover;
    border-radius: 2.5px;
  }

  h2,
  p {
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  h2 {
    font-size: 0.75rem;
    margin-bottom: 3px;
  }

  p {
    font-size: 0.5rem;
    letter-spacing: 0;
    font-weight: lighter;
  }

  .top {
    flex: 68%;
    border-radius: 2.5px;
    width: 100%;
    position: relative;
  }

  .bottom {
    flex: 32%;
    width: 100.4%;
    border-radius: 0 0 2.5px 2.5px;
    margin-bottom: -1px;
    animation: preventFlickering 1s linear;
    @include largeDown {
      width: 100.7%;
    }
    .info {
      padding: 0 7.5px 7.5px 9px;
      min-height: 27.5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include desktop {
        padding-bottom: 6px;
      }
      .text {
        z-index: 1;
        h2 {
          -webkit-line-clamp: 1;
        }
        p {
          -webkit-line-clamp: 1;
        }
      }
      .icons {
        z-index: 2;
        display: inline-flex;
        margin-right: -8px;
        padding-left: 7.5px;
        .infocircle {
          display: block;
          margin-right: 7px;
          border-radius: 50%;
          transform: scale(1.06);
          &:hover {
            background-color: rgba(255, 255, 255, 0.37);
          }

          &:active {
            background-color: rgba(255, 255, 255, 0.07);
          }

          @include desktop {
            margin-right: 6.5px;
          }
        }
        svg {
          z-index: 2;
          font-size: 1.2rem;
          width: 1.2rem;
          height: 1.2rem;
          @include desktop {
            font-size: 1.3rem;
            width: 1.3rem;
            height: 1.3rem;
          }
        }
      }
    }
    .description {
      padding: 0 7.5px 18px 9px;
      border-radius: 0 0 2px 2px;

      @include largeDown {
        padding-bottom: 10.5px;
      }

      p {
        letter-spacing: 0;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 11px;
        min-height: 22px;

        @include largeDown {
          -webkit-line-clamp: 2;
          line-height: 9px;
          min-height: 18px;
        }
        @include desktop {
          line-height: 8px;
          min-height: 16px;
        }
      }
    }
  }
}

@keyframes preventFlickering {
  0% {
    height: 0%;
    opacity: 0;
  }
  25% {
    height: 100%;
    opacity: 0.25;
  }
  50% {
    height: 100%;
    opacity: 0.5;
  }
  75% {
    height: 100%;
    opacity: 0.75;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
