@import "styles/utils";
@import "styles/variables";

$container-padding: 0 130px 0 var(--app-padding-left-large);
$container-padding-desktop: 0 50px 0 var(--app-padding-left-desktop-tablet);
$container-padding-tablet: 0 50px 0 var(--app-padding-left-desktop-tablet);
$container-padding-phone: 0 20px 0 var(--app-padding-left-phone);

.item {
  position: relative;
  margin: 0;
  padding: 0;
  background: linear-gradient(180deg, #110c49 0%, #070523 100%);
}

.itemSkeleton {
  padding-top: 43.75%;
}

.background::before {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: var(--app-menu-top-fade-height);
  background: var(--app-menu-top-fade-gradient);
  z-index: 1;

  @include tablet {
    height: calc(var(--app-menu-top-fade-height) * 1.5);
  }
}

.background {
  background: linear-gradient(to top,
      var(--bg-color) 0%,
      rgba(255, 255, 255, 0) 50%);
  box-shadow: inset 0 -27px 19px -9px var(--bg-color);

  @include phone {
    background: linear-gradient(to top,
        var(--bg-color) 0%,
        rgba(255, 255, 255, 0) 25%);
  }
}

.container {
  box-sizing: border-box;
  position: absolute;
  bottom: 55px;
  width: 85%;
  padding: $container-padding;

  @include phone {
    bottom: 2px;
    padding: $container-padding-phone;
  }

  @include tablet {
    width: 100%;
    padding: $container-padding-tablet;
  }

  @include desktop {
    width: 100%;
    padding: $container-padding-desktop;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @include desktop {
    width: 70%;
  }

  @include tablet {
    width: 70%;
  }

  @include phone() {
    width: 100%;
  }
}

.title {
  font-size: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 51px;
  letter-spacing: normal;
  padding-bottom: 4px;
  margin: 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include tabletDown {
    font-size: 2rem;
    word-wrap: break-word;
    padding: 0;
    line-height: 30px;
  }

  @include tablet {
    font-size: 2rem;
  }

  @include desktopDown {
    -webkit-line-clamp: 2;
  }

  @include phone {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 26px;
    white-space: normal;
  }
}

.infoContainer {
  margin-bottom: 1.2rem;

  @include phone() {
    margin: 0;
  }
}

.description,
.additionalDescription {
  margin-bottom: 0;
  font-size: 1.12rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0.68px;

  @include phone() {
    font-size: 16px;
    margin: 0;
  }
}

.description {
  font-weight: normal;
  max-height: 96px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.additionalDescription {
  opacity: 0.8;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
  margin-top: 2rem;

  @include phone() {
    display: none;
  }
}

.skeleton {
  position: absolute;
  width: 70%;
  bottom: 150px;
  left: 150px;
}

.mediaAvailabilityDate {
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.68px;
}

.mediaStartInfo {
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.68px;
}

.trailer {
  margin-left: 0.7rem;
}
