@import "styles/utils";
@import "styles/variables";

.Dialog {
  color: var(--primary-text-color);

  :global(.Dialog-content) {
    background-color: var(--bg-color);
  }

  :global(.Dialog-close-x) {
    color: var(--primary-text-color);

    @include tabletDown {
      display: none;
    }
  }
}

.logo {
  display: block;
  margin: auto;
  padding-top: 20px;
  height: 72px;

  @include tabletDown {
    margin-bottom: 40px;
  }

  @include phone {
    padding-top: 40px;
  }
}

.textContainer {
  max-width: 460px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: lighter;
  color: var(--primary-text-color);

  strong {
    font-weight: normal;
  }

  h3 {
    font-weight: lighter;
    font-size: inherit;
  }

  .title {
    text-align: center;
    font-size: 50px;
    margin-top: 0;

    @include tabletDown {
      font-size: 24px;
    }
  }

  @include tablet {
    font-size: 16px;
  }

  @include phone {
    font-size: 14px;
  }
}

.link {
  font-weight: normal;
}
.buttonContainer{
  display: flex;
  justify-content: center;
}
.button {
  display: block;
  margin-bottom: 40px;
  margin-top:20px;
  min-width: 250px;
  width: max-content;
  height: 54px;
  font-size: 24px;
  font-weight: normal;

  @include tabletDown() {
    font-size: 18px;
  }

  @include phone() {
    margin: 60px auto;
  }
}
.dotsContainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}