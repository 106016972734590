@import "styles/utils";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .price,
  .description {
    padding-left: 5px;
    font-weight: 300;
  }

  .title {
    font-weight: bolder;
  }
}