@import "styles/utils";

.large {
  :global {
    .slick-list {
      margin: 0 420px 0  var(--app-padding-left-large) !important;
    }

    @include large {
      .slick-list {
        margin: 0 325px 0 var(--app-padding-left-large) !important;
    }
  }

    @include desktop {
      .slick-list {
        margin: 0 255px 0  var(--app-padding-left-desktop-tablet) !important;
    }
  }

    @include tablet {
      .slick-list {
        margin: 0 150px 0  var(--app-padding-left-desktop-tablet) !important;
    }
  }

    @include phone {
      .slick-list {
        margin: 0 70px 0  var(--app-padding-left-phone) !important;
    }
  }
  }
}
