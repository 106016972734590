@import "styles/utils";
@import "styles/variables";

.select {
  margin-top: 48px;

  .title {
    margin-bottom: -18px;
    font-size: 1.4rem;
    font-weight: normal;

    @include tabletDown() {
      margin-bottom: -10px;
      font-size: 1.88rem;
    }
  }

  .itemsBox {
    margin-left: -10px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.7rem;
    font-weight: normal;
    list-style: none;

    @include tabletDown() {
      font-size: 1.9rem;
    }
    @include phone() {
      font-size: 2.5rem;
    }
  }

  .item {
    @include prevent-tab-highlights;
    margin: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41px;
    height: 41px;
    border: 1.5px solid var(--primary-text-color);
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;

    @include hover {
      &:hover {
        background-color: rgba(255, 255, 255, 0.37);
      }
    }
    
    &:active {
      background-color: rgba(255, 255, 255, 0.07);
    }

    &Active {
      background-color: var(--primary-color);
      border: none;

      &:hover {
        background-color: var(--button-primary-hover-color);
      }

      &:active {
        background-color: var(--button-primary-active-color);
      }
    }

    @include tabletDown() {
      width: 30px;
      height: 30px;
    }
  }
}
