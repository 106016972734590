.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--bg-color);
  opacity: 0.58;
  z-index: 100;
}
