@import "styles/utils";

.Container {
  font-size: 14px;

  @include phone {
    font-size: 18px;
  }

  .error {
    font-size: 1.6rem;
    color: $error-color;
  }
}

.Table {
  margin-top: 2.5em;
  font-size: 18px;

  @include tablet {
    font-size: 14px;
  }
}

.Action {
  @include prevent-tab-highlights;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 3px;
  width: 24px;
  height: 24px;

  @include tablet {
    width: 20px;
    height: 20px;
  }

  @include phone {
    padding: 18px 3px;
  }

  svg {
    width: 10px;
    height: auto;
  }
}

.Delete {
  cursor: pointer;
}