@import "styles/utils";

.item {
  min-width: 35px;
  font-size: 1.05rem;
  font-weight: lighter;
  letter-spacing: 1.1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  color: var(--primary-text-color);

  &:hover {
    font-weight: 900;
  }

  &::after {
    content: attr(data-title);
    content: attr(data-title) / "";
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: 900;
  }
  @include tabletDown {
    font-size: 0.9rem;
    min-width: 20px;
    padding: 0 6px;
  }

  @include phone {
    font-size: 1.4rem;
    min-width: 60px;
  }
}

.itemActive {
  font-weight: normal;
}
