@import "styles/components";
@import "styles/utils";

.list {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @include phone() {
    width: 70%;
    flex: none;
  }

  @include phone-landscape() {
    width: 80%;
  }

  justify-content: space-between;

  .listRow {
    width: 100%;
    @include paddingVertical(3rem);

    .detailContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      @include typography(1.5rem, 1.6rem);
      flex: 6;

      @include phone() {
        flex: 11;
        @include typography(22px, 24px);
      }
    }

    .description {
      @include typography(1.3rem, 1.6rem);
      display: flex;
      flex: 6;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: lighter;

      @include phone() {
        flex: 1;

        .descriptionDetails {
          display: none;
        }
      }

      .rightArrow {
        margin-left: 20px;
      }
    }

    .arrow {
      background-color: $primary;
    }
  }
}