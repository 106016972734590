@import "styles/utils";
@import "styles/components";

.landingPage {
  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    span {
      @include typography(50px, 52px);

      @include phone() {
        @include typography(2.9rem, 3rem);
        text-align: center;
      }

      font-weight: bold;
    }
  }
}

.bottomButtonSeeAllContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  overflow-anchor: none;

  &:first-of-type {
    padding-top: 220px;

    @include phone() {
      padding-top: 90px;
    }
  }

  &:not(:first-of-type) [id$="highlightListItem"]>div>div>div {
    &::before {
      display: none;
    }
  }

  &:not(:first-of-type) {
    padding-top: var(--app-list-padding-top);

    @include desktop() {
      padding-top: 35px;
    }

    @include tablet() {
      padding-top: 30px;
    }

    @include phone() {
      padding-top: 20px;
    }
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--app-padding-left-large);
  padding-right: 48px;
  line-height: 4.25rem;

  @include largeDown() {
    line-height: 3.5rem;
  }

  @include desktopDown() {
    padding-left: var(--app-padding-left-desktop-tablet);
    padding-right: 30px;
  }

  @include phone() {
    padding-left: var(--app-padding-left-phone);
    padding-right: 15px;
    line-height: 4rem;
  }
}

.title {
  font-size: 1.5rem;
  letter-spacing: 0;
  font-weight: normal;

  &:first-child {
    :global {
      .react-loading-skeleton {
        @include phone {
          width: 10rem !important;
        }
      }
    }
  }
}

.subTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
  text-align: center;

  @include phone() {
    margin-bottom: 30px;
  }

  span {
    @include typography(1.5rem, 1.9rem);
    font-weight: lighter;
    max-width: 60%;
  }
}

.seeAll {
  @include prevent-tab-highlights;
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 4.25rem;
  padding-right: 14px;
  opacity: 1;
  transition: font-weight 0.3s;
  font-weight: lighter;

  &:hover {
    font-weight: normal;
  }

  @include largeDown() {
    line-height: 3.5rem;
  }

  @include tabletDown {
    padding-right: 9px;
  }

  @include phone() {
    line-height: 4rem;
  }
}

.plainSeeAll {
  margin: 50px 0 0 0 !important;
  padding: 14px 60px 14px 60px !important;

  @include phone() {
    margin: 30px 0 0 0 !important;
    height: 40px !important;
    padding: 0 30px 0 30px !important;

    >span {
      @include typography(2.1rem, 2.5rem);
    }
  }
}

.container {
  position: relative;
  width: 100vw;

  @include phone() {
    padding-left: 0;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.list {
  :global {
    .slider {
      overflow: hidden;
    }

    .slick-list {
      overflow: visible;
      margin: 0 230px 0 var(--app-padding-left-large);

      @include large {
        margin: 0 180px 0 var(--app-padding-left-large);
      }

      @include desktop {
        margin: 0 145px 0 var(--app-padding-left-desktop-tablet);
      }

      @include tablet {
        margin: 0 110px 0 var(--app-padding-left-desktop-tablet);
      }

      @include phone {
        margin: 0 45px 0 var(--app-padding-left-phone);
      }
    }

    .cover-list {
      .slick-list {
        margin: 0 105px 0 var(--app-padding-left-large);

        @include large {
          margin: 0 90px 0 var(--app-padding-left-large);
        }

        @include desktop {
          margin: 0 75px 0 var(--app-padding-left-desktop-tablet);
        }

        @include tablet {
          margin: 0 45px 0 var(--app-padding-left-desktop-tablet);
        }

        @include phone {
          margin: 0 35px 0 var(--app-padding-left-phone);
        }
      }
    }

    .circle-list {
      .slick-list {
        margin: 0 115px 0 58px;

        @include large {
          margin: 0 95px 0 58px;
        }

        @include desktop {
          margin: 0 75px 0 28px;
        }

        @include tablet {
          margin: 0 55px 0 28px;
        }

        @include phone {
          margin: 0 45px 0 15px;
        }
      }
    }

    .slick-track {
      margin-left: 0px;
      margin-right: 0px;
    }

    .slick-dots {
      width: max-content;
      left: 50%;
      bottom: 72px;
      transform: translateX(-50%);
      text-align: center;
      box-sizing: border-box;

      @include phone {
        display: none !important;
      }

      &>li,
      &>li>button {
        width: auto;
        height: auto;
        padding: 0;
        margin: 0;
      }

      &>li>button:before {
        position: relative;
        display: inline-block;
        opacity: 0.6;
        width: 8px;
        height: 8px;
        padding: 0;
        margin: 4px;
        border-radius: 50%;
        font-size: 0;
        border: 0;
        cursor: pointer;
        background-color: var(--primary-text-color);
      }

      .slick-active>button:before {
        opacity: 1;
        background-color: var(--primary-color);
      }
    }

    .slick-current {
      z-index: 1;
    }
  }
}