@import "styles/utils";

.icon {
  transition: all 0.2s ease;
  background-color: transparent;
  svg {
    position: absolute;
    top: 72%;
    left: 50%;
    font-size: 1.8rem;
    transform: translate(-50%, -50%);

    path:first-child {
      fill: var(--primary-color);
    }

    @include large {
      top: 70%;
      font-size: 2.1rem;
    }
    @include desktop {
      top: 75%;
      font-size: 1.9rem;
    }
  }
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 220%;
}
