@import "styles/utils";

.ContentContainer {
  padding: 10px 0;
}

.DialogWrap {
  bottom: 0 !important;
}

.DialogContainer {
  margin: 50px auto !important;
  max-width: 790px;
}

  .Text {
    font-size: 22px;
    line-height: 24px;
    font-weight: lighter;
    max-width: 100%;
    @include phone {
      padding: 50px;
    }
  }

  .ActionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    @include phone {
      flex-direction: column-reverse;
      align-items: center;
    }

    button {
      padding: 14px 22px 14px 22px;
      width: 45%;
      @include phone {
        padding: 22px;
        margin-top: 30px;
        max-width: 100%;
        min-width: 70%;
      }
    }

    .CancelButton {
      border-color: $primary;
      >span {
        color: $primary;
      }
    }
  }