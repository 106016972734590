@import "src/styles/utils";
@import "src/styles/variables";

.coverImg {
  position: absolute;
  border-radius: 5px;
  height: 434px;
  width: auto;
  z-index: 2;

  @include desktopDown() {
    height: 274px;
  }

  @include tabletDown() {
    height: 155px;
    border-radius: 4px;
  }

  @include phone() {
    height: 150px;
  }
}
