@import "styles/utils";

.progressBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--primary-text-color) 3%,
    var(--primary-text-color) 100%
  );
  overflow: hidden;

  &.hidden {
    background: transparent;
  }

  .bar {
    @include transition-transform(2s ease-out);
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 0 4.5px 4.5px 0;
    z-index: 10;
  }

  .buffored {
    @include transition-transform(2s ease-out);
    position: absolute;
    height: 100%;
    background: grey;
    z-index: 9;
  }
}
