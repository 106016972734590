@import "styles/utils";
@import "styles/variables";

.watchHistory {
  .description {
    margin: -2px 0 30px 0;
    font-size: 1rem;

    @include tabletDown() {
      font-size: 1.16rem;
    }

    @include phone() {
      font-size: 1.65rem;
    }
  }

  .table {
    width: calc(100vw - 500px);
    min-width: 430px;
    max-width: 800px;

    @include desktopDown() {
      width: calc(100vw - 450px);
    }

    @include tabletDown() {
      min-width: unset;
      width: unset;
    }

    table {
      width: 100%;
    }
  }

  .row {
    height: 36px;
    font-size: 1.38rem;
    line-height: 18px;

    @include tabletDown() {
      height: 34px;
      font-size: 1.26rem;
    }

    @include phone() {
      font-size: 1.75rem;
    }

    td {
      &:nth-child(1),
      &:nth-child(2) {
        padding-right: 20px;
      }

      &:nth-child(1) {
        min-width: 70px;
        font-weight: lighter;
      }

      &:nth-child(2) {
        font-weight: bold;
      }
    }
  }

  .deleteButton {
    @include prevent-tab-highlights;
    cursor: pointer;

    svg {
      width: 17px;
      height: 17px;
    }
  }
}
