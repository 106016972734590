@import "styles/utils";

.subprofileSelect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include phone {
    padding-bottom: 50px;
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
  }

  .profileList {
    margin-top: -6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 500px;

    & div {
      margin: 12px 5px 5px 5px;
    }

    & div > div:first-of-type {
      height: 70px;
      width: 70px;
      cursor: pointer;

      & > span {
        font-size: 44px;
      }
    }

    & div > div:last-of-type {
      margin-top: 14px;
      font-size: 20px;
    }
  }

  .checkboxContainer {
    margin-top: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span:first-of-type {
      margin-right: 15px;
    }

    span:last-of-type {
      font-size: 20px;
      font-weight: lighter;
    }

    @include phone {
      padding-left: 15px;

      span:first-of-type {
        margin-right: 10px;
      }
    }
  }
}
