@import "styles/utils";
@import "styles/variables";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text {
    margin-top: 40px;
    font-weight: lighter;
    font-size: 1.1rem;

    @include tablet {
        text-align: center;
        font-size: 1.55rem;
    }

    @include phone {
        font-size: 2.1rem;
    }
}