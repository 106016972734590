@import "styles/utils";

.EnterPinCodeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include phone {
    padding-bottom: 400px;
  }

  h2 {
    margin: 0 0 -26px 0;
    font-size: 36px;

    @include phone {
      margin-top: 50px;
    }
  }

  .ProfileCircle {
    @include phone {
      margin: 80px 10px 20px 10px;
    }

    & div:first-of-type {
      width: 70px;
      height: 70px;

      span {
        font-size: 44px;
      }
    }

    & div:last-of-type {
      margin-top: 5px;
      font-size: 20px;
    }
  }

  .CodeContainer {
    position: relative;

    .Error {
      position: absolute;
      width: max-content;
      left: 50%;
      bottom: -33px;
      transform: translateX(-50%);
      color: #ec1b2e;
      text-align: center;
      font-size: 12px;
    }
  }

  .CodeInputs {
    margin-top: 10px;
    display: flex;
  }

  .ForgotPin {
    margin-top: 78px;
    font-size: 15px;

    @include phone {
      margin-top: 31px;
    }
  }
}
