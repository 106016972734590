@import "styles/components";
@import "styles/utils";

$iconSize: 65px;

.container {
  background-color: var(--app-dot-color);
  .subTitle{
    @include typography(1.5rem, 1.6rem);
    font-weight: lighter;
    color: var(--primary-text-color);
    margin-bottom: 2rem;
    @include phone() {
      @include typography(2rem, 2.6rem);
      text-align: center;
      max-width: 90%;
    }
  }
  .list {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 60%;
    @include phone(){
      width:90%;
    }
    .row {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      @include phone() {
        flex-basis: 100%;
        flex-direction: column;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: $iconSize;
        height: $iconSize;
        @include phone() {
          margin-top: 0;
          margin-bottom: 3rem;
        }
        > svg {
          width: $iconSize;
          height: $iconSize;
        }
      }
      .elementContainer {
        display: flex;
        flex: 4;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include paddingVertical(1.3rem);
        .title {
          @include typography(1.5rem, 1.6rem);
          font-weight: bold;
          @include phone() {
            @include typography(2rem, 2.6rem);
          }
        }
      }
    }
  }
  .browseButton {
    margin: 50px 0 0 0;
    padding: 14px 60px;

    @include phone() {
      margin: 30px 0 0 0;
      height: 40px;
      padding: 0 30px;
      
      > span {
        @include typography(2.1rem, 2.5rem);
      }
    }
  }
}
