.DialogContainer {
  margin: 50px auto !important;
}

.ContentContainer {
  padding: 10px 0;
}

.DialogWrap {
  bottom: 0 !important;
}