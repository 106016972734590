@import "styles/variables";
@import "styles/utils";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 8rem;

  @include tablet {
    width: 90%;
    margin: 18rem auto 0;
  }

  @include phone() {
    margin: 40px 0 0;
  }

  form {
    width: 29rem;

    @include phone() {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
    }
  }

  .buttonLine {
    width: 100%;
    text-align: center;
  }
}

.line {
  width: 100%;
  text-align: center;
}

.inputContainer {
  border-radius: 5.4px;
  border: 1px solid $input-bg;
  background-color: $input-bg;
  width: 29rem;
  height: 47px;
  margin: 6px 0 15px 0;
  padding: 0 10px;

  @include phone() {
    width: 100%;
  }
}

.input {
  padding: 0 5px;
  color: $primary;
  font-size: $input-font-size;
  line-height: 47px;
  font-weight: normal;

  @include phone() {
    width: 100%;
  }
}

.error {
  text-align: center;
  color: $error-color;
}
