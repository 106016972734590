@import "styles/variables";
@import "styles/utils";

.error {
  font-size: 1.4rem;
  color: $error-color;

  @include tabletDown() {
    font-size: 1.8rem;
  }

  @include phone() {
    font-size: 2.4rem;
  }

  &Centered {
    text-align: center;
  }
}
