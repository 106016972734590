$tooltip-text-color: var(--primary-text-color);
$tooltip-background-color: var(--primary-color);
$tooltip-margin: 30px;
$tooltip-arrow-size: 6px;

.tooltip {
  position: relative;
  display: inline-block;
}

.content {
  position: absolute;
  top: -$tooltip-margin;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  border-radius: 4px;
  color: $tooltip-text-color;
  background: $tooltip-background-color;
  white-space: nowrap;
  z-index: 100;

  &::before {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    border: 1px solid transparent;
    border-top-color: $tooltip-background-color;
    height: 0;
    width: 0;
    pointer-events: none;
    border-width: $tooltip-arrow-size;
    margin-left: -$tooltip-arrow-size;
  }
}