.placeholderContainer {
  position: relative;
  padding-top: 133.33%;
}

.placeholder {
  width: 100%;
  height: 100%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.image {
  width: 100%;
  height: 100%;
}
