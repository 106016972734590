.Bold {
  font-weight: bold;
}

.ContentTite {
  font-weight: bold;
  margin-bottom: 0;
}

.ContentInfo {
  margin-top: 0;
}

.PeriodContainer {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 1.6rem;

  button {
    margin: 10px 0 0 0;
  }

  button:disabled {
    background: #BCBCBC;
  }
}