@import "styles/utils";

:global(.slick-slide) {

  .item,
  .itemSkeleton {
    margin: 0 21px;
    padding-top: 24px;

    @include largeDown() {
      margin: 0 16px;
    }

    @include tabletDown() {
      padding-top: 15px;
    }

    @include phone() {
      margin: 0 9px;
    }
  }
}

.imageContainer {
  position: relative;
  padding-top: 100%;
  border-radius: 50%;
  background: var(--primary-color);
  transition: transform 0.4s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}

.skeleton {
  position: relative;
  padding-top: 100%;
}

.image {
  cursor: pointer;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 24px;
}