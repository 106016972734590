@import "styles/utils";
@import "styles/variables";

.SettingsSubscriptionButton {
  margin: 0;
  padding: 0 30px !important;
  height: 50px !important;
  font-size: 20px !important;
  border-radius: $button-radius;
  font-weight: bold;
  text-align: center;
  box-shadow: none;
  letter-spacing: 0.82px;

  &Wider {
    max-width: 295px;
  }

  @include tablet {
    min-width: 170px;
    font-size: 16px;
  }

  @include phone(){
    height: 50px !important;
    font-size: 18px !important;
  }
}
