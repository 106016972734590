@import "styles/utils";
@import "styles/components";

.signUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
  scroll-snap-align: start;

  @include phone() {
    min-height: 100vh;
  }

  @include phone-landscape() {
    min-height: 140vh;
  }

  .formContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 140px;
    z-index: 30;
    height: 100%;
    width: 362px;

    @include phone() {
      min-height: 100vh;
      position: static;
      background-color: rgba(7, 5, 35, 0.6);
      width: 90%;
      @include paddingHorizontal(20px);
      left: 0;
    }

    @include phone-landscape() {
      min-height: 100vh;
      margin-bottom: 50px;
      left: 50px;
    }
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    object-position: left;

    @include phone() {
      object-position: center;
    }
  }
}