@import "styles/utils";

.item {
  transition: all 0.2s;
  animation: showItem 0.3s ease-in;
}

:global(.slick-slide) {

  .item,
  .containerSkeleton {
    margin: 0 12px 0 0;

    @include large() {
      margin: 0 10px 0 0;
    }

    @include desktopDown() {
      margin: 0 9px 0 0;
    }

    @include phone() {
      margin: 0 7px 0 0;
    }
  }

}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.isOutOfView .container {
  cursor: default;
}

.container {
  cursor: pointer;
  border-radius: 4px;
  background: radial-gradient(#002DEB 0%, #070523 100%);
  position: relative;
  padding-top: 56.25%;
}

.skeleton {
  padding-top: 56.25%;
}

@keyframes showItem {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}