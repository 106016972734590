@import "styles/utils";

.menuItem {
  @include prevent-tab-highlights;
  margin-top: 27px;
  opacity: 0.55;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0;
  list-style-type: none;
  cursor: pointer;
  position: relative;

  @include tablet {
    margin-top: 22px;
  }

  @include phone {
    opacity: 1;
    margin-top: 38px;
  }

  @include desktop {
    &:first-of-type {
      margin-top: 0;
    }
  }

  @include hover {
    &:hover {
      color: var(--primary-text-color);
      opacity: 1;
    }
  }
}

.menuItemActive {
  opacity: 1;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include phone() {
    font-size: 16px;
    line-height: 20px;
  }
}