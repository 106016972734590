@import "src/styles/utils";

.logo {
  display: none;
  position: absolute;
  top: 48px;
  left: 78px;
  z-index: 10;

  @include tabletDown() {
    display: block;
    top: 48px;
    left: 48px;
  }

  @include phone() {
    top: 96px;
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    height: 60px;
    width: 53px;

    @include phone() {
      height: 70px;
      width: 73px;
    }
  }
}
