@import "styles/utils";

.label {
  position: absolute;
  top: -18px;
  right: 15px;
  border-radius: 20px;
  padding: 8px 16px;
  z-index: 1;

  @include phone() {
    font-size: 10px;
    padding: 4px 6px;
    right: 2px;
    top: -6px;
  }

  @include tablet() {
    font-size: 12px;
    padding: 6px 10px;
    right: 5px;
    top: -6px;
  }

  @include desktop() {
    font-size: 14px;
    padding: 8px 16px;
    right: 5px;
    top: -6px;
  }

  @include large() {
    font-size: 18px;
    padding: 8px 16px;
    right: 5px;
  }
}

.availableSoon {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-size: 1.375rem;
}

.expiresSoon {
  background-color: var(--bg-gray);
  color: var(--bg-color);
  font-size: 1rem;
}