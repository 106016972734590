@import "styles/utils";
@import "styles/variables";

.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  margin: 0 auto;

  .icon {
    height: 50px;
    width: 50px;

    path:first-child {
      fill: var(--primary-color);
    }
  }

  .info {
    font-size: 16px;
    text-align: left;
    font-weight: normal;
  }

  .error {
    font-size: 16px;
    text-align: left;
    font-weight: normal;
    color: red;
    text-align: center;
  }
}

.loading {}

.link {
  font-weight: normal;
}