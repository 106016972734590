@import "styles/utils";
@import "styles/variables";

.container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: radial-gradient(#002DEB 0%, #070523 100%);
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.modal {
  @include prevent-tab-highlights;
  cursor: pointer;
  z-index: 1009;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease;
  opacity: 0;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 12px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2.5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg-color);

  @include tabletDown {
    display: none;
  }
}

@keyframes preventFlickering {
  0% {
    height: 0%;
    opacity: 0;
  }

  25% {
    height: 100%;
    opacity: 0.25;
  }

  50% {
    height: 100%;
    opacity: 0.5;
  }

  75% {
    height: 100%;
    opacity: 0.75;
  }

  100% {
    height: 100%;
    opacity: 1;
  }
}