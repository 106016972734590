@import "styles/utils";
@import "styles/variables";

.Container {
  margin-top: 6px;
  //margin-bottom: 52px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include tablet {
    font-size: 1.28rem;
  }

  @include phone {
    font-size: 2rem;
  }
}

.checkmark{
 color: var(--app-dot-color);
 width: 24px;
 height: 24px;
}
.InfoText{
    font-size: 1.12rem;
    font-weight: lighter;
    margin-top: 0;
    margin-bottom: 1rem;
    span{
        font-weight: bold;
    }
    @include tablet {
        font-size: 1.55rem;
    }

    @include phone {
        font-size: 2.1rem;
    }
}

.FatText {
  font-weight: bold;
}

.StatusIndicator {
  border-radius: 100px;
  width: 20px;
  height: 20px;
}

.buttonContainer{
  margin-top: 3rem;
  button {
    margin-top:20px;
  }
}

.Item {
  color: var(--primary-text-color);
  font-weight: lighter;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: -12px;
  }

  .ItemTitle {
    font-weight: bold;
    margin-right: 10px;
  }
}

.TitlePrefix {
  display: flex;
  margin-bottom: 40px;
  font-weight: lighter;

  svg {
    margin-right: 8px;
    width: 28px;
    color: var(--primary-color);
  }
}

.ButtonPrefix {
  margin: 90px 0 0 0;

  & + div {
    margin-top: 0;
  }
}

.PartnerInfo {
  font-weight: lighter;
  font-size: 1.12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 !important;
  @include tablet {
    font-size: 1.55rem;
  }

  @include phone {
    font-size: 2.1rem;
  }
}

.PartnerInfoText {
  margin-top: 0;
  margin-bottom: 1rem;
}

.Bold {
  font-weight: bold;
}

.ButtonContainer {
  margin-top: 3rem;
}

.PaymentDetailType {
  font-size: 1rem;
  margin-top: 0;
}
