@import "styles/utils";
@import "styles/components";

:global(.slick-slide) {
  .item {
    margin: 0 20px 0 0;

    @include large() {
      margin: 0 16px 0 0;
    }

    @include desktopDown() {
      margin: 0 10px 0 0;
    }

    @include phone() {
      margin: 0 8px 0 0;
    }
  }
}

.item {
  position: relative;
  background: rgba(26, 20, 99, 0.40);
  border: 2px solid rgba(14, 33, 97, 0.48);
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.itemActive {
  .gradient {
    width: 116%;
    top: 60%;
    right: -1%;
    background: #0031FF;
    filter: blur(68.95px);
    border-radius: 114px;
    transform: rotate(8.32deg);
  }

  &:not(.rowHovered) {
    .dust {
      background-image: url("/assets/moving-dust.gif");
    }
  }
}

.itemHovered {
  &:not(.itemActive) {
    opacity: 1;

    .gradient {
      background: #181484;
      filter: blur(68.95px);
      top: 76%;
      right: 22%;
      transform: rotate(6.63deg);
    }

    .dust {
      background-image: url("/assets/moving-dust.gif");
    }
  }
}

.itemFaded {
  opacity: 0.6;
}

.container {
  position: relative;
  overflow: hidden;
  padding-top: 41%;
}

.information {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 26px;
}

.role {
  @include typography(1.25rem, normal);
  margin-bottom: 8px;
}

.name {
  @include typography(1.5625rem, normal);
  font-weight: 700;
}

.icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  @include typography(2.125rem);
}

.gradient {
  position: absolute;
  width: 134%;
  height: 77%;
  top: 76%;
  right: 22%;
  transform: rotate(8.32deg);
  filter: blur(54.85px);
  border-radius: 21px;
  background: #19166C;
}

.dust {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("/assets/moving-dust.png");
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: color-dodge;
}