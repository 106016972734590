@mixin typography($fontSize, $lineHeight:0) {
  font-family: Raleway, serif;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin paddingVertical($padding) {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin paddingHorizontal($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin marginVertical($margin) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin marginHorizontal($margin) {
  margin-left: $margin;
  margin-right: $margin;
}