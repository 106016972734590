// color scheme
$primary: #101010;
$primary-text: #ffffff;
$secondary: #233661;
$secondary-text: #9d9d9d;
$primary-bg: #101010;
$secondary-bg: #101010;
$disabled: #233661;
$other: #1eb89b;
$icon-color: #ffffff;
$error-color: #d32f2f;
$success-color: #4bb543;
//assets
$icons-root: "../icons/" !default;

$screen-resolution-tv-large: "screen and (min-width : 1920px)";
$screen-resolution-tv-small: "screen and (max-width : 1919px)";
$screen-resolution-wuxga: 1920px;

// ***** Breakpoints for Responsive Screen
// width scale for phone
$phone-max-width: 499px !default;
$phone-min-width: 375px !default;
// height scale for phone
$phone-min-height: 346px !default;
$phone-max-height: 695px !default;
// width scale for tablet
$tablet-min-width: 500px !default;
$tablet-max-width: 849px !default;
// height scale for tablet
$tablet-min-height: 800px !default;
$tablet-max-height: 1440px !default;
// Desktop Scale
$desktop-min-width: 850px !default;
$desktop-max-width: 1100px !default;
// Large Scale
$large-min-width: 1100px !default;
$large-max-width: 1399px !default;

// Button
$button-font-default: 1.375rem;
$button-height-default: 38px;
$button-height-lg: 70px;
$button-height-md: 40px;
$button-height-sm: 30px;
$button-bg: #ffa000;
$button-radius: 27px;

// Loader
$loader-height-default: 24px;

// Input
$input-radius: 27px;
$input-bg: #f2f2f2;
$input-font-size: 16px;

// Label
$label-font-size: 16px;

// headers
$h1-font-size: 34px;
