@import "src/styles/utils";
@import "src/styles/variables";

.list {
  margin-top: 58px;
  padding-left: 0;
  list-style: none;

  @include tabletDown() {
    margin-top: 20px;
  }

  &Item {
    margin-bottom: -14px;
    display: flex;
    align-items: baseline;

    p {
      font-size: inherit;
      font-weight: lighter;
      max-width: 680px;

      span {
        text-transform: capitalize;
      }
      span:not(:last-child):after {
        content: ", ";
      }
    }

    h3 {
      font-size: inherit;
      font-weight: normal;
      padding-right: 50px;
      width: 240px;
      min-width: 240px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include desktopDown() {
        padding-right: 10px;
        width: 200px;
        min-width: 200px;
      }

      @include phone() {
        width: 100px;
        min-width: 100px;
      }
    }
  }
}
