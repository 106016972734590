@import "src/styles/utils";
@import "src/styles/variables";

$padding-left-adjustment-vector: 20px;

.mediaDetailsTopContent {
  padding-left: calc(
    var(--app-padding-left-large) - $padding-left-adjustment-vector
  );
  position: absolute;
  display: flex;
  align-items: self-end;
  bottom: 0;
  width: calc(100% - 530px);
  z-index: 1;

  @include desktopDown() {
    padding-left: calc(
      var(--app-padding-left-desktop-tablet) - $padding-left-adjustment-vector
    );
    bottom: -135px;
    width: calc(100% - 346px);
  }

  @include tablet() {
    padding-left: calc(var(--app-padding-left-large) + 8px);
    width: calc(100% - var(--app-padding-right) - 86px);
    bottom: -58px;
  }

  @include phone() {
    padding-left: calc(var(--app-padding-left-large) / 2 - 24px);
    width: calc(100% - 46px);
    bottom: -58px;
  }

  .leftIcon {
    display: block;
    padding: 26px 16px 26px 0px;

    @include desktopDown() {
      padding: 26px 26px 0 0px;
    }

    @include tabletDown() {
      display: none;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include tabletDown() {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .coverImg {
    display: none;

    @include tabletDown() {
      display: block;
      position: relative;
      right: unset;
    }
  }

  .buttonsBox {
    position: relative;
    display: flex;
    width: max-content;

    @include tabletDown() {
      margin-top: 10px;
      flex: 1 100%;
    }

    .trailerButton {
      svg {
        &:hover {
          border-radius: 999px;
          background-color: rgba(255, 255, 255, 0.37);
        }

        &:active {
          border-radius: 999px;
          background-color: rgba(255, 255, 255, 0.07);
        }
      }
    }

    & button:nth-child(1) {
      margin-right: 47px;
    }

    .progressBar {
      bottom: -32px;
      min-width: 312px;
      border-radius: 4.5px;
    }
  }
}

.leftIcon {
  align-self: flex-start;
  display: none;

  @include tabletDown() {
    padding: 10px 20px 20px 0;
    display: block;
    position: absolute;
    top: 10%;
    left: 20px;
    z-index: 2;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
