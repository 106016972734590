.container {
  margin-top: 50px;
  font-size: 18px;

  span:not(:first-child) {
    font-weight: 300;
  }
}

.paymentType {
  font-weight: bolder;
}

.cardType {
  text-transform: capitalize;
}
