@import "styles/utils";

.header {
  margin-bottom: 50px;

  h2 {
    font-size: 25px;

    @include phone {
      font-size: 20px;
    }
  }
}
.subprofileForm {
  font-size: 16px;

  .input {
    max-width: 100%;
  }

  .checkboxContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .sliderHeader {
    text-align: left;
    align-self: flex-start;
    margin: 30px 0 17px 0;
    font-weight: normal;
  }

  .buttonContainer {
    display: flex;
  }

  .button {
    margin-top: 32px;
    padding: 0 24px;
    height: 43px;

    i {
      margin-left: -12px;
    }

    span {
      width: max-content;
      font-size: 16px;
      font-weight: normal;
    }
  }

  .initialPageContainer {
    padding-top: 24px;
    
    &__title {
      font-size: 16px;
      line-height: 19px;
      color: var(--gray-text-color);
    }
    &__description {
      display: block;
      font-size: 16px;
      line-height: 22px;
      color: var(--primary-text-color);
      padding-bottom: 16px;
      font-weight: 300;
    }
  }
}
