@import "styles/utils";
@import "styles/variables";

.button {
  @include prevent-tab-highlights;
  font-weight: normal;
  margin-right: 1em;
  font-size: $button-font-default;
  font-family: var(--font-family);
  object-fit: contain;
  border-radius: 35px;
  color: var(--primary-text-color);
  border: 1px solid var(--primary-text-color);
  background-color: rgba(0, 0, 0, 0);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}

.circular {
  height: 45px;
  width: 45px;
}

.buttonLoading {
  opacity: 0.6;
}

.primary {
  border-color: var(--primary-color);
  background-color: var(--primary-color);

  @include hover {
    &:hover {
      background-color: var(--button-primary-hover-color);
      border-color: var(--button-primary-hover-color);
    }
  }

  &:active {
    background-color: var(--button-primary-active-color);
    border-color: var(--button-primary-active-color);
  }

  svg {
    margin-left: 6px;
  }
}

.plain {
  border: 2.6px solid var(--primary-text-color);

  @include hover {
    &:hover {
      background-color: rgba(255, 255, 255, 0.37);
    }
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.07);
  }
}

.trailer {
  margin-left: 1rem;
  border: 2.6px solid var(--primary-text-color);

  svg {
    margin-left: 6px;
  }
}

.iconElevated {
  box-shadow: 0 0 20px 0 var(--primary-color-d-50);

  + .text {
    padding-left: 10px;
  }
}

.loader {
  width: $loader-height-default;
  height: $loader-height-default;

  svg {
    circle {
      stroke: var(--primary-text-color);
    }
  }
}

.secondary {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);

  .iconElevated {
    box-shadow: 0 0 20px 0 var(--secondary-d-50);
  }
}

.link {
  border-color: var(--link-color);
  background-color: var(--link-color);

  .iconElevated {
    box-shadow: 0 0 20px 0 var(--link-d-50);
  }
}

.transparent {
  border: 3px solid var(--primary-text-color);

  .iconElevated {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
  }
}
.no-border {
  border:none;
  margin:0 !important;
  padding:0 !important;
  p{
    margin:0 !important;
    padding:0 !important;
  }
}

.icon {
  width: $button-height-default;
  height: $button-height-default;

  svg {
    width: 20px;
    height: 22px;
  }
}
.disabled {
  background: #eeeeee;
  border-color: #eeeeee;
  cursor: default;

  @include hover {
    &:hover {
      border-color: #eeeeee;
      background-color: #eeeeee;
    }
  }

  &:active {
    border-color: #eeeeee;
    background-color: #eeeeee;
  }
}

.icon,
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  + .text {
    padding-left: 0;
  }
}

@include phone {
  .button {
    height: $button-height-sm;
  }

  .circular {
    width: $button-height-md;
  }

  .icon {
    width: $button-height-sm;
    height: $button-height-sm;
  }
}

@include tablet {
  .button {
    height: $button-height-md;
  }

  .circular {
    width: $button-height-md;
  }

  .icon {
    width: $button-height-md;
    height: $button-height-md;
  }
}

@include desktop {
  .icon {
    width: $button-height-md;
    height: $button-height-md;
  }
}

@include large {
  .icon {
    width: $button-height-md;
    height: $button-height-md;
  }
}
