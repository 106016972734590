@import "styles/variables";
@import "styles/components";
@import "styles/utils";

.container {
  background-color: var(--app-dot-color);

  .list {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;

    .listRow {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      @include paddingVertical(3rem);

      @include phone-landscape() {
        flex-basis: 50%;
      }

      .starsRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        .star {
          width: 25px;
          height: 25px;
          @include typography(25px);
          @include paddingHorizontal(0.4rem)
        }
      }

      .title {
        @include typography(1.3rem, 1.8rem);
        @include marginVertical(1rem);
        min-height: 80px;
        text-align: center;

        @include phone() {
          min-height: auto;
          @include typography(1.6rem, 3rem);
        }

        @include phone-landscape() {
          min-height: auto;
          @include typography(1.6rem, 3rem);
        }
      }

      .reviewSource {
        @include typography(01rem, 1rem);
        font-weight: lighter;

        @include phone() {
          @include typography(1.5rem, 1.7rem);
          max-width: 90%;
        }
      }
    }
  }
}