@import "styles/utils";
$checkbox-size: 25px;

.contactsForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  gap: 1rem;

  > div {
    margin: 0;

    > div {
      > div {
        text-align: left;
      }
    }
  }

  > * input[type="text"] {
    width: 100%;
  }

  > button {
    align-self: center;
    height: 48px;
    font-size: 20px;
    max-width: 215px;

    @include tablet {
      height: 40px;
      font-size: 16px;
      max-width: 175px;
    }
  }
}

.phoneFormContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  > div > div > label {
    position: relative;
    margin-left: -80px;
  }

  > div > div > div {
    position: relative;
    display: inline-block;
    width: 100%;
    right: 20%;
    text-align: left;
  }

  > div > div > ul {
    position: relative;
    right: 20%;
  }
  span {
    border-radius: 0 5px 5px 0 !important;
  }
}

.phoneContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.formInput {
  padding: 0 10px;
}

.emailChanged input {
  font-style: italic;
}

.nameInputs {
  display: flex;
  gap: 5px;

  > div {
    width: 50%;
    margin: 0;
  }

  ul {
    word-break: break-all;
  }
}

.consent {
  display: flex;
  margin-top: 3rem;

  p {
    margin-top: 0px;
    font-weight: lighter;
  }
}
.consentText{
  font-size: 14px;
  font-weight: lighter;
  line-height: 18px;
}
.textContainer {
  margin-left: 1rem;
  font-size: 14px;
  font-weight: lighter;
  color: #eeeeee;

  > :first-child {
    margin-top: 0;
  }
}

.spinnerbox {
  margin-top: 20px;

  @include phone {
    display: flex;
    justify-content: center;
  }
}

.error {
  margin-bottom: 36px;
}

.noError {
  margin-bottom: 27px;
}
