.checkbox {
  span,
  input {
    margin: 0;
    width: 24px;
    height: 24px;
  }

  span {
    display: block;
    position: relative;
    margin-right: 15px;
    background-color: var(--primary-text-color);
    border-radius: 3px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 7px;
      width: 6px;
      height: 14px;
      border: solid var(--primary-color);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg) scale(0);
      pointer-events: none;
      transition: opacity 0.14s, transform 0.2s;
      opacity: 0;
      z-index: 0;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &:checked + span:after {
      transform: rotate(45deg) scale(1);
      opacity: 1;
    }
  }
}
