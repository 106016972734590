@import "styles/variables";
@import "styles/utils";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 1rem;

  .inputContainer {
    width: 100%;
    height: 47px;
    margin: 6px 0 15px 0;
    padding: 0 10px;
    border: 1px solid $input-bg;
    border-radius: 5.4px;
    background-color: $input-bg;
  }

  .inputError {
    @extend .inputContainer;
    border: 1px solid $error-color;
  }
}

.labelContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.label {
  font-size: $label-font-size;
  letter-spacing: 0.6px;
  font-weight: normal;
}

.required {
  color: var(--primary-color);
}

.fallbackContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.error {
  display: inline-block;
  font-size: 12px;
  color: $error-color;
  list-style: none;
  margin-top: 0;
  padding: 0;
  text-align: left;
  flex: 50%;
}

.tip {
  display: inline-block;
  color: #eeeeee;
  font-size: 11px;
  text-align: right;
  flex: 50%;
  width: 100%;
}
