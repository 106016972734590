@import "styles/variables";

.ContentTite {
  font-weight: bold;
  margin-bottom: 0;
}

.Reason {
  margin-bottom: 18px;
  display: flex;
}

.ReasonText {
  margin-left: 10px;
}

.SubReason {
  margin-left: 30px;
}

.SubReasonHidden,
.TextAreaContainerHidden {
  display: none;
}

.Textarea {
  width: 100%;
  font-size: $input-font-size;
  letter-spacing: 0.6px;
  border: none;
  background-color: #ffffff;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 1.6rem;

  button {
    margin: 10px 0 0 0;
  }
}