@import "styles/components";
@import "styles/utils";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
}

.fullHeight {
  @include phone() {
    min-height: 100vh;
  }
}

.withPadding {
  @include paddingVertical(3rem);
  @include paddingHorizontal(3rem);
}

.autoHeight {
  height: auto;
}