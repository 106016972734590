@import "styles/utils";

.list {
  :global {
    .slick-list {
      overflow: hidden !important;
      margin: 3.9rem var(--app-padding-right) 3.9rem
        var(--app-padding-left-large) !important;

      @include desktopDown() {
        margin-left: var(--app-padding-left-desktop-tablet) !important;
        margin-right: var(--app-padding-left-desktop-tablet) !important;
      }

      @include phone {
        margin: 3.6rem 0 6.4rem 0 !important;
      }
    }
  }
}
