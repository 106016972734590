@import "styles/utils";

$height-adjustment: 2px;
//margin top is taken from item circle to count position of all circle arrows
$item-circle-margin-top-default: 20px;
$item-circle-margin-top-tablet-phone: 15px;
//circle-list margin-right is divided by 1.5 to count width of right circle arrows:
$circle-background-right-width-default: 77px;
$circle-background-right-width-large: 63px;
$circle-background-right-width-desktop: 50px;
$circle-background-right-width-tablet-phone: 37px;
//circle-list margin-left is divided by 2 to count width of left circle arrows:
$circle-background-left-width-default: 29px;
$circle-background-left-width-desktop-tablet: 14px;
$circle-background-left-width-phone: 12.5px;

.container {
  z-index: 5;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  pointer-events: none;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.disabled {
    display: none;
  }

  .arrowBox {
    position: relative;
    height: 100%;
    .backgroundLeft,
    .backgroundRight {
      filter: blur(8px);
      margin-right: -9px;
      margin-left: -9px;
      margin-top: -2px;
      width: 195px;
      height: 101%;
      @include tablet {
        width: 125px;
      }
      @include phone {
        width: 75px;
      }
    }
    svg {
      cursor: pointer;
      pointer-events: initial;
      position: absolute;
      top: 50%;
      padding: 16px;
      transform: translateY(-50%);
      font-size: 30px;
      opacity: 0.8;
      @include phone {
        font-size: 24px;
      }
    }
    .iconRight {
      right: 0;
      @include tablet {
        padding: 14px 9px 14px 5px;
      }
      @include phone {
        padding: 9px 4px;
      }
    }
    .iconLeft {
      left: 0;
      @include tablet {
        padding: 14px 5px 14px 9px;
      }
      @include phone {
        padding: 9px 4px;
      }
    }
  }
}

:global(.circle-list) {
  .container {
    top: $item-circle-margin-top-default - $height-adjustment;
    height: calc(100% - $item-circle-margin-top-default);
    @include tabletDown {
      top: $item-circle-margin-top-tablet-phone - $height-adjustment;
      height: calc(100% - $item-circle-margin-top-tablet-phone);
    }
    .arrowBox {
      .backgroundLeft,
      .backgroundRight {
        filter: blur(10px);
      }

      .backgroundRight {
        border-top-left-radius: 20%;
        border-bottom-left-radius: 20%;
        width: $circle-background-right-width-default;
        @include large {
          width: $circle-background-right-width-large;
        }
        @include desktop {
          width: $circle-background-right-width-desktop;
        }
        @include tabletDown {
          width: $circle-background-right-width-tablet-phone;
        }
      }

      .backgroundLeft {
        border-top-right-radius: 20%;
        border-bottom-right-radius: 20%;
        width: $circle-background-left-width-default;
        @include large {
          width: $circle-background-left-width-default;
        }
        @include desktop {
          width: $circle-background-left-width-desktop-tablet;
        }
        @include tablet {
          width: $circle-background-left-width-desktop-tablet;
        }
        @include phone {
          width: $circle-background-left-width-phone;
        }
      }
      .iconRight {
        padding-right: 10px;
        @include tablet {
          padding-right: 5px;
        }
        @include phone {
          padding-right: 3px;
        }
      }
      .iconLeft {
        padding-left: 10px;
        @include tablet {
          padding-left: 5px;
        }
        @include phone {
          padding-left: 3px;
        }
      }
    }
  }
}
