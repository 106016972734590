@import "styles/utils";

.applicationHeaderItem {
  border: none;
  text-align: center;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  margin-right: 2em;
  padding: 1em 2em;

  img {
    height: 2em;

    @include hover {
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
