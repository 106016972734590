@import "styles/utils";

$container-padding: 0 100px 50px 60px;
$container-padding-tablet: 0 50px 12px 30px;
$container-padding-phone: 0px 20px 22px 68px;

.item {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  width: 100%;
  height: 100%;
}

.itemSkeleton {
  display: flex;
}

.background {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  @include phone {
    display: block;
  }
}

.image {
  flex: 1 55%;
  align-self: flex-start;
  cursor: pointer;

  > div {
    border-radius: 5px;
  }

  @include tablet {
    flex: 1 65%;
  }
}

.imageSkeleton {
  border: 1px solid var(--cell-background-color);
  box-sizing: border-box;
  padding-top: 37.25%;
}

.container {
  position: relative;
  flex: 1 55%;
  display: flex;
  align-items: center;

  @include tablet {
    align-items: flex-start;
  }

  @include phone {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .containerSkeleton {
    background: var(--cell-background-color);
    border: solid 1px #212121;
    border-left: none;
    box-sizing: border-box;
  }
}

.content {
  padding-right: 30px;
  margin-left: 15%;
  max-width: 530px;

  @include largeDown {
    margin-left: 10%;
    padding-right: 0;
  }

  @include desktop {
    padding-right: 0;
  }

  @include tablet {
    padding-right: 0;
  }
  @include phone {
    width: 90%;
    padding: 0 var(--app-padding-left-phone);
    margin: 0;
  }
}

.datetime {
  text-transform: uppercase;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.68px;
  padding-bottom: 8px;
  color: var(--primary-color);
}

.headerDescr {
  word-wrap: break-word;
  margin: 0 0 2rem 0;
  font-size: 3rem;
  font-size: clamp(1.5rem, 2.5vw, 3rem);

  @include desktop {
    margin: 0;
    width: 100%;
    font-size: 2rem;
    font-size: clamp(1.5rem, 2.5vw, 2rem);
  }

  @include tablet {
    margin: 0;
    width: 100%;
    font-size: 2rem;
    font-size: clamp(1.5rem, 2.5vw, 2rem);
  }

  @include phone {
    width: 100%;
    word-wrap: normal;
    margin: 0;
    font-size: 22px;
  }
}

.title {
  margin: 6px 0 0;
  font-size: 1.375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  @include desktop {
    margin: 0.5rem 0 0 0;
    font-size: 1.2rem;
  }

  @include tablet-portrait {
    font-size: 1rem;
  }

  @include phone {
    font-size: 16px;
  }
}

.description {
  margin: 1.25rem 0 2rem 0;
  font-size: 1.2rem;
  font-size: clamp(0.9rem, 1.25vw, 1.2rem);
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.375;
  overflow: hidden;
  width: 100%;
  color: var(--primary-text-color);

  @include desktop {
    margin: 1rem 0 1rem 0;
    line-height: 1.2;
    font-size: 1rem;
  }

  @include tablet {
    margin: 1rem 0 1rem 0;
    line-height: 1.2;
    font-size: 1rem;
    font-size: clamp(8px, 1.25vw, 1rem);
  }

  @include phone {
    display: none;
  }
}

.additionalInfo {
  font-size: 1.375rem;
  opacity: 0.8;
  font-weight: lighter;

  @include desktop {
    font-size: 1.2rem;
  }

  @include tablet {
    font-size: 1rem;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-start;

  @include phone {
    display: none;
  }
}

.skeleton {
  position: absolute;
  width: 100%;
  top: 55%;
  left: 50px;
  transform: translateY(-50%);
}

.playButton {
  @include tabletDown {
    display: none;
  }
}
