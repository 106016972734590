@import "styles/variables";
@import "styles/utils";

.container {
  @include phone() {
    margin-top: 30px;
  }

  form {
    @include tabletDown() {
      width: unset;
    }
  }

  p {
    font-size: 17px;

    &.userExist,
    &.userValidated {
      font-size: 13px;
      font-weight: 100;
      margin-top: -15px;
      opacity: 0.7;
    }
  }
}

.title {
  margin-bottom: 34px;
  font-size: 50px;

  @include tablet {
    font-size: 45px;
  }

  @include phone {
    display: none;
  }
}

.resetPassword {
  position: absolute;
  bottom: -4px;
  right: 0;
  color: var(--primary-text-color);
  align-self: flex-end;
}

.labelFieldContainer {
  position: relative;
  height: 100px;

  &>span {
    @include tabletDown() {
      width: 100%;
    }
  }
}

.buttonWrapper {
  margin: 28px 0 26px 0;
  text-align: center;

  button {
    width: 215px;
    height: 50px;
  }
}

.passwordUpdatedText {
  margin-bottom: -20px;
  font-weight: lighter;
  font-size: 16px;
}