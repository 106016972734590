@import "styles/utils";

.PasswordForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 415px;
  width: 100%;
  form {
    width: 100%;
    > div {
      > div div {
        text-align: left;
      }
    }
  }
  .ButtonLine {
    display: flex;
    justify-content: center;

    > button {
      height: 50px;
      font-size: 18px;
      max-width: 250px;

      @include tablet {
        height: 40px;
        font-size: 16px;
        max-width: 175px;
      }
    }
  }

  .information {
    margin-top: 52px;
    font-size: 16px;
    font-weight: lighter;
  }
}
