@import "styles/utils";
.ParentalControlCode {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  max-width: 440px;

  @include phone {
    margin-top: 30px;
    flex-wrap: wrap;
  }

  .PinCodeButton {
    height: 46px;
    width: 100%;
    max-width: 160px;
    font-weight: normal;
    font-size: 20px;

    @include tablet {
      font-size: 16px;
    }

    @include phone {
      margin: 20px 0 20px 0;
      height: 46px;
      width: 37%;
    }
  }

  .ParentalControlCodeInput {
    display: flex;
    flex-direction: row !important;
  }

  .ParentalContorlIputContainer {
    background: none;

    @include phone {
      margin: 0 10px 0 0;
    }

    &:last-child {
      margin-right: 20px;
    }

    &,
    .ParentalContorlInput {
      width: 40px;
      height: 40px;
    }

    .ParentalContorlInput {
      text-align: left;
      font-size: 27px;
      font-weight: bold;
      border-width: 1px;
      color: var(--primary-text-color);
      border-radius: 6.4px;
      box-shadow: 0 0 0px 1000px transparent inset !important;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.CodeContainer {
  position: relative;
  display: flex;

  .Error {
    position: absolute;
    bottom: -8px;
    width: max-content;
    color: #ec1b2e;
    font-size: 12px;
    align-self: flex-start;
  }
}

.CodeInputs {
  margin-top: 10px !important;
  display: flex;
  flex-direction: row !important;
}

.InputCode {
  margin: 0 22px 0 0;

  & > div {
    display: none;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  @include tabletDown {
    margin-right: 10px;
  }

  .InputContainer {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;

    &Error {
      input {
        border-color: #ec1b2e;
      }
    }
  }

  .Input {
    text-align: center;
    font-size: 41.6px;
    color: var(--primary-text-color);
    border: 1.6px solid var(--primary-text-color);
    border-radius: 6.4px;
    box-shadow: 0 0 0px 1000px transparent inset !important;
  }

  &,
  .Input {
    width: 64px;
    height: 64px;
  }
}
