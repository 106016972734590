@import "styles/utils";
.welcomeContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top:100px;

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 300px;
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    font-weight: lighter;
    >h1{
      font-size: 24px;
      line-height: 30px;
    }
  }
  .badgeContainer {
    padding-top:37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .androidBadge{
    width:200px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .IOSdBadge{
    width:200px;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: auto;
      border-radius: 13px;
    }
  }
  .myAccountLink{
    width: 100%;
    text-align: left;
    a{
      font-weight: bold;
    }
  }
  .dotsContainer{
    min-height: 70vh;
    display: flex;
    @include phone() {
      display: none;
    }

    @include phone-landscape(){
      display: none;
    }

  }
  .listContainer{
    margin-top: 50px;
    min-height: 80vh;
    width: 100%;
    .list {
      padding: 0;
      width: 100%;
      list-style-type: none;
      li {
        font-weight: 900;
        font-size: 24px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        span {
          margin-top:10px;
          font-family: Raleway;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 20px;
          font-weight:300;
          max-width: 240px;
        }
        svg {
          position: absolute;
          right: 0;
          top:0;
          width:22px;
          height:22px;
          pointer-events: none;
        }
      }
    }
  }



}