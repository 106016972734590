@import "styles/variables";
@import "styles/utils";

.container {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border-radius: 5.4px;
  margin: 6px 0 15px 0;
  padding: 0 10px;
  border: 1px solid $input-bg;
  background-color: $input-bg;
}

.input {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  color: $primary;
  font-size: $input-font-size;
  letter-spacing: 0.6px;
  background: transparent;
  list-style: none;
  text-align: start;
  border: none;

  &::-ms-reveal {
    display: none;
  }

  &:focus {
    outline: 0;
  }
}

.suffix {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 30px;
    height: 21px;
    color: #bbbbbb;
  }
}

.clear,
.icon {
  width: 28px;
  height: 28px;
  font-size: 28px;
  margin-left: 8px;

  img {
    width: 100%;
  }

  svg {
    display: block;
  }
}

.clear {
  cursor: pointer;
}

.clearHidden {
  visibility: hidden;
}
