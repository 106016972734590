@import "styles/utils";
@import "styles/variables";

.button {
  justify-content: center;
  width: 100%;
  max-width: 275px;
  height: 54px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-right: 0;
  border-radius: $button-radius;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.82px;
  text-align: center;

  @include phone() {
    max-width: 215px;
    height: 48px;
    font-size: 20px;
  }
}
